import { useState, useEffect } from 'react'
import { createContainer } from 'unstated-next'
import { UserContextContainer } from '../../../infrastructure/signIn/userContext'
import * as api from '../../../infrastructure/api'
import { snackbars } from '../../../infrastructure/snackbars'
import { t } from '../../../infrastructure/i18nextHelper'
import {
    Site,
    Company,
    StockInput,
    defaultStockInput,
    StockProduct,
    StockInputValue,
    DutyStatus,
    SiteCompanyProduct,
} from '../stockModels'
import { StockBoardContainer } from '../stockBoardStore'

function useActualStock() {
    let [sites, setSites] = useState<Site[]>([])
    let [companies, setCompanies] = useState<Company[]>([])
    let [stockInput, setStockInput] = useState<StockInput>(defaultStockInput)
    let [stockInputValuePerCompany, setStockInputValuePerCompany] = useState<{ [key: string]: StockInputValue[] }>({})
    let [products, setProducts] = useState<StockProduct[]>([])
    let [dutyStatuses, setDutyStatuses] = useState<DutyStatus[]>([])
    let [siteCompanyProducts, setSiteCompanyProducts] = useState<SiteCompanyProduct[]>([])
    let [availableCompanies, setAvailableCompanies] = useState<Company[]>([])
    let [availableProducts, setAvailableProducts] = useState<StockProduct[]>([])
    let [stockDocumentLoader, setStockDocumentLoader] = useState<number>(0)

    let stockBoardStore = StockBoardContainer.useContainer()
    let userContext = UserContextContainer.useContainer()

    useEffect(() => {
        if (userContext.isLoggedIn) return
        setCompanies([])
        setSites([])
        setDutyStatuses([])
        setProducts([])
    }, [userContext.isLoggedIn])

    useEffect(() => {
        if (products && companies && siteCompanyProducts) {
            setAvailableCompanies(companies.filter(x => siteCompanyProducts.map(e => e.company).distinct().includes(x.name)))
            setAvailableProducts(products.filter(x => siteCompanyProducts.map(e => e.product).distinct().includes(x.code)))
        }
    }, [siteCompanyProducts, products, companies])

    useEffect(() => { if (stockInput.date && stockInput.site) loadSiteCompanyProduct() }, [stockInput.date, stockInput.site])

    useEffect(() => {
        if (stockInput.date && stockInput.site) loadStock()
    }, [siteCompanyProducts])

    let countrys = () => companies.map(x => x.country).distinct()

    let init = async () => {
        let companysPromise = api.get<Company[]>('stock/company')
        let sitesPromise = api.get<Site[]>('stock/site')
        let productsPromise = api.get<StockProduct[]>('stock/product')

        let companys = await companysPromise
        let sites = await sitesPromise
        let products = await productsPromise

        let selectedSite = sites.length === 1 ? sites.first().code : null

        setCompanies(companys)
        setSites(sites)
        setProducts(products)
        setDutyStatuses(companys.flatMap(x => x.dutyStatuses).distinct())

        let tmpStockInputValue: StockInputValue[] = []
        products.forEach(p =>
            companys.forEach(c =>
                c.dutyStatuses.forEach(d => tmpStockInputValue.push(
                    {
                        productId: p.id,
                        productCode: p.code,
                        isCalibration: false,
                        dutyStatus: d,
                        company: c.code,
                        companyName: c.name
                    }
                ))
            )
        )

        setStockInput({ ...stockInput, values: tmpStockInputValue, site: selectedSite })
    }

    let loadSiteCompanyProduct = async () => {
        setSiteCompanyProducts(await api.get<SiteCompanyProduct[]>(`stock/${stockInput.site}/companyProducts`))
    }

    let setStockInputValue = (index: number, newValue: StockInputValue) => {
        let array = stockInput.values
        array[index] = newValue
        setStockInput({ ...stockInput, values: array })
        setStockInputValuePerCompany(array.indexMultipleValueByProp('company'))
    }

    let setSiteCapacityPercentage = (stockInputToCalculate: StockInput) => {
        let calculatedStockInput = { ...stockInputToCalculate }

        calculatedStockInput.siteCapacitys.forEach((siteCapacity) => {
            let capacity = calculatedStockInput.values.filter(x => x.productId == siteCapacity.productId)
                .map((x) => x.volume ?? 0).reduce((acc, curr) => acc + curr, 0)

            if (siteCapacity.lowVolume && capacity <= siteCapacity.lowVolume)
                siteCapacity.percentageByHigh = 0
            else if (siteCapacity.highVolume && capacity >= siteCapacity.highVolume)
                siteCapacity.percentageByHigh = 100
            else if (siteCapacity.highVolume)
                siteCapacity.percentageByHigh = Math.round((capacity * 100) / siteCapacity.highVolume)

            if (siteCapacity.minVolume && capacity <= siteCapacity.minVolume)
                siteCapacity.percentageByMax = 0
            else if (siteCapacity.maxVolume && capacity >= siteCapacity.maxVolume)
                siteCapacity.percentageByMax = 100
            else if (siteCapacity.maxVolume)
                siteCapacity.percentageByMax = Math.round((capacity * 100) / siteCapacity.maxVolume)
        })

        return {
            ...stockInputToCalculate,
            siteCapacitys: calculatedStockInput.siteCapacitys
        }
    }

    let loadStock = async () => {
        let actualStockOrigin = await api.get<StockInput>('stock', { date: stockInput.date, site: stockInput.site })
        let actualStock = setSiteCapacityPercentage(actualStockOrigin)

        setStockInputValuePerCompany(actualStock.values.indexMultipleValueByProp('company'))
        setStockInput({
            ...stockInput,
            stockId: actualStock.stockId,
            version: actualStock.version,
            isCalibration: actualStock.isCalibration,
            accountingPeriodStatus: actualStock.accountingPeriodStatus,
            values: actualStock.values,
            projectedQuantitys: actualStock.projectedQuantitys,
            siteCapacitys: actualStock.siteCapacitys,
            calibratedAt: actualStock.calibratedAt,
            calibratedBy: actualStock.calibratedBy
        })

        setStockDocumentLoader(stockDocumentLoader + 1)
    }

    let onSave = async () => {
        if (stockInput.isCalibration)
            snackbars.warning(t('stock.label.warnings.calibrationWarning'))
        try {
            await api.post('stock', stockInput)
            snackbars.success(t('httpSuccess.actualStockSaved'))
        } catch (err) {
            throw err
        }
        finally {
            loadStock()
        }
    }

    return {
        init, loadSiteCompanyProduct,
        sites, setSites,
        companies, setCompanies, countrys,
        stockInput, setStockInput,
        products, setProducts,
        availableCompanies, setAvailableCompanies,
        availableProducts, setAvailableProducts,
        dutyStatuses, setDutyStatuses, setStockInputValue,
        siteCompanyProducts, setSiteCompanyProducts,
        stockInputValuePerCompany, setStockInputValuePerCompany,
        loadStock, onSave, stockDocumentLoader
    }
}

export let ActualStockContainer = createContainer(useActualStock)